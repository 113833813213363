import React from 'react';
import { CircularProgress } from '@mui/material';

export default function Spinner({ size }) {
  return (
    <div className="spin">
      <CircularProgress size={size} />
    </div>
  );
}
