import React, { createContext, useMemo, useState } from 'react';

const LogInContext = createContext();

function LogInProvider({ children }) {
  const [logIn, setLogIn] = useState(false);

  const setLogIns = (status) => setLogIn(status);

  const contextValue = useMemo(() => ({ logIn, setLogIns }), [logIn, setLogIns]);

  return (
    <LogInContext.Provider value={contextValue}>
      {children}
    </LogInContext.Provider>
  );
}

export { LogInProvider, LogInContext };
