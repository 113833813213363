/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Popover,
} from '@mui/material';
import {
  ArrowRightAlt,
  MoreHoriz,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AuthHelper from '../../utils/AuthHelper';
import TitlebarImageList from './ImageSilder';
import ActiveUserList from './ActiveUserList';
import MediaControlCard from './Challenge';
import Users from '../../utils/api/services/users';
import Challenge from '../../utils/api/services/challenge';
import { LogInContext } from '../../isLoggedIn';

const drawerWidth = 355;
const drawerRightWidth = 340;
const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    border: 'none',
  },

  drawerPaperright: {
    width: drawerRightWidth,
    border: 'none',
    backgroundColor: '#ECEEF9',
    BorderColor: '#ECEEF9',
  },
  profile: { marginTop: '10px', marginLeft: '10px' },
  profileInfo: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  plans: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  ImgInfo: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
    borderRadius: 1,
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoContainer: {
    position: 'absolute',
    right: '0',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px',
  },
  main: { padding: '1rem' },
  subHead: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
  },
  deleteText: {
    padding: 10,
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px',
  },
  buttonStyle: {
    padding: 2,
    margin: 5,
  },
  buttonStyledanger: {
    padding: 2,
    margin: 5,
    backgroundColor: 'rgba(240, 83, 62, 1)',
  },
}));

export default function RightBarDrawerContent({ setopen, open }) {
  const { setLogIns } = useContext(LogInContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const loggedUser = JSON.parse(localStorage.getItem('User'));
  const [userLoad, setUserLoad] = React.useState(false);
  const [challege, setChallege] = React.useState([]);
  const [user, setUser] = React.useState([]);
  const [challegeLoad, setChallegeLoad] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorLogoutEl, setAnchorLogoutEl] = React.useState(null);
  const logoutopen = Boolean(anchorLogoutEl);
  const id = logoutopen ? 'simple-popover' : undefined;
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseLogout = () => {
    setAnchorLogoutEl(null);
  };
  const logoutHandler = () => {
    setLogIns(false);
    navigate('/login');
    AuthHelper.clearAll();
  };
  const getUsers = async () => {
    try {
      setUserLoad(true);
      const response = await Users.getUsers(0, 3);
      if (response?.status === 200) {
        setUser(response?.data?.datas);
      }
      setUserLoad(false);
    } catch (err) {
      setUser([]);
      setUserLoad(false);
    }
  };
  const getChallege = async () => {
    try {
      setChallegeLoad(true);
      const response = await Challenge.getChallegeCategory(0, 3);
      if (response?.status === 200) {
        setChallege(response?.data?.datas);
      }
      setChallegeLoad(false);
    } catch (err) {
      setChallege([]);
      setChallegeLoad(false);
    }
  };
  useEffect(() => {
    getUsers();
    getChallege();
  }, []);

  return (
    <div className="rightbar-content">
      <Box className="profile-info mar">
        <Box>
          <IconButton onClick={() => setopen(!open)}>
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      </Box>
      <Box className="right-main">
        <Box className="profile-info">
          <Box className="info">
            <Avatar alt="Remy Sharp" src="https://picsum.photos/200/300" />
            <h6 className="profile">{loggedUser?.email}</h6>
          </Box>
          <Box>
            <MoreHoriz
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={(e) => setAnchorLogoutEl(e.currentTarget)}>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <div className="layout-grid overflow-hidden">
          <Box className="plans">
            <Typography className={classes.subHead}><b>Our Plans</b></Typography>
            <Box>
              <ArrowRightAlt />
            </Box>
          </Box>
          <TitlebarImageList />
        </div>
        <div className="layout-grid">
          <Typography component="h1" className={classes.subHead}><b>Active Users</b></Typography>
          {user?.map((item) => (
            <>{item?.status === 'active' && <ActiveUserList key={item} data={item} />}</>
          ))}
        </div>
        <div className="layout-grid">
          <Typography className={classes.subHead}><b>Challenges</b></Typography>
          {challege?.map((item) => (
            <MediaControlCard key={item} data={item} />
          ))}
        </div>
      </Box>

      <Popover
        id={id}
        open={logoutopen}
        anchorEl={anchorLogoutEl}
        onClose={handleCloseLogout}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className="popover-content"
      >
        <Typography className={classes.deleteText}>
          Are you sure to logout?
        </Typography>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.buttonStyle}
            variant="contained"
            onClick={handleCloseLogout}
          >
            No
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: 'red' }}
            onClick={logoutHandler}
          >
            Yes
          </Button>
        </div>
      </Popover>
    </div>
  );
}
