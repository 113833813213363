/* eslint-disable comma-dangle */
import { lazy } from 'react';

const routes = [
  {
    path: '/',
    element: lazy(() => import('../pages/login')),
    auth: false
  },
  {
    path: '/resetPassword',
    element: lazy(() => import('../pages/resetPassword')),
    auth: false
  },

  {
    path: '/login',
    element: lazy(() => import('../pages/login')),
    auth: false
  },
  {
    path: '/forgotpassword',
    element: lazy(() => import('../pages/forgotpassword')),
    auth: false
  }
];
export default routes;
