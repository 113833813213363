import * as React from 'react';
import {
  Box, Card, CardContent, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import CircularStatic from './ProgressBar';

const useStyles = makeStyles(() => ({
  card: { marginBottom: '1rem', marginTop: '1rem' },
  subHead: {
    fontFamily: 'Poppins',
    fontFtyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#787878',
  },
  content: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '22px',
    color: '#232323',
  },
}));
export default function MediaControlCard({ data }) {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Card className={classes.card} elevation={0}>
      <Box>
        <CardContent>
          <div
            style={{
              display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer',
            }}
            onClick={() => navigate('/challenges/challenge-details', { state: data })}
          >
            <div>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                className={classes.subHead}
              >
                {data?.description}
              </Typography>
              <Typography component="h6" variant="h6" className={classes.content}>
                <small>{data?.title}</small>
              </Typography>
            </div>
            <div><CircularStatic /></div>
          </div>
        </CardContent>
      </Box>
    </Card>
  );
}
