import jwtDecode from 'jwt-decode';

class AuthHelper {
  static #authTokenKey = 'token';

  static #rememberme = 'RememberMeFastingApp';

  static #authRefreshToken = 'refreshToken';

  static #user = 'User';

  /**
   * Set the AuthToken value
   *
   * @param {string} token
   * @returns {void}
   */
  static setAuthToken(token) {
    return localStorage.setItem(this.#authTokenKey, token);
  }

  static setAuthRefresh(token) {
    return localStorage.setItem(this.#authRefreshToken, token);
  }

  /**
   * Clearing the AuthToken from local storage
   *
   * @returns {void}
   */
  static putRememberMe(rememberme) {
    return localStorage.setItem(this.#rememberme, JSON.stringify(rememberme));
  }

  /**
   * Clearing the RememberMe data from local storage
   *
   * @returns {void}
   */
  static ClearRememberMe() {
    return localStorage.removeItem(this.#rememberme);
  }

  /**
   * get the RememberMe data from local storage
   *
   * @returns {void}
   */

  static getRememberMe() {
    return JSON.parse(localStorage.getItem(this.#rememberme));
  }

  /**
   * Set the user data
   *
   * @param {string} token
   * @returns {void}
   */
  static setUser(user) {
    return localStorage.setItem(this.#user, JSON.stringify(user));
  }

  /**
   * Getting the AuthToken
   *
   * @returns {string}
   */
  static getAuthToken() {
    return localStorage.getItem(this.#authTokenKey);
  }

  /**
   * Getting the AuthToken
   *
   * @returns {string}
   */
  static getLoggedInUserRole() {
    const { role } = JSON.parse(localStorage.getItem('User'));
    return role;
  }

  /**
   * Getting the AuthToken
   *
   * @returns {string}
   */
  static getLoggedInUserId() {
    const user = JSON.parse(localStorage.getItem('User'));
    return user;
  }

  /**
   * Clearing data from local storage
   *
   * @returns {void}
   */
  static clearAll() {
    // localStorage.clear();;
    localStorage.removeItem(this.#authTokenKey);
    localStorage.removeItem(this.#authRefreshToken);
    localStorage.removeItem('User');
  }

  /**
   * Clearing the AuthToken from local storage
   *
   * @returns {void}
   */
  static clearAuthToken() {
    return localStorage.removeItem(this.#authTokenKey);
  }

  /**
   * Clearing the user data from local storage
   *
   * @returns {void}
   */
  static clearUser() {
    return localStorage.removeItem(this.#user);
  }

  /**
   * It is used for check whether a user is logged in or not
   *
   * @returns {boolean}
   */
  static validToken() {
    try {
      const token = this.getAuthToken();
      jwtDecode(token);
      if (jwtDecode(token).exp < Date.now() / 1000) {
        localStorage.clear();
        return false;
      }
      return true;
    } catch (errorObj) {
      return false;
    }
  }

  /**
   * It is used for check whether a user is logged in or not
   *
   * @returns {boolean}
   */
  static isLoggedIn() {
    try {
      const token = this.getAuthToken();
      jwtDecode(token);
      if (jwtDecode(token).exp < Date.now() / 1000) {
        // localStorage.clear();
      }
      return true;
    } catch (errorObj) {
      return false;
    }
  }
}

export default AuthHelper;
