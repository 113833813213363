import * as React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import dataUrl from '../common/config/url';

export default function AppHeader() {
  return (
    <Box>
      <Grid container spacing={3}>
        {dataUrl?.map(
          (item) => item?.url === window.location.pathname && (
          <Grid item xs={12} key={item?.name}>
            <Typography
              variant="h5"
              component="h5"
              style={{ marginBottom: '10px', color: '#000' }}
            >
              <b>{item?.name}</b>
            </Typography>
          </Grid>
          ),
        )}
      </Grid>
    </Box>
  );
}
