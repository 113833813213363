import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import { RouteList } from './routes/index';
import RequireAuth from './components/RequireAuth';
import CheckAuth from './components/RequireAuth/CheckAuth';
import Spinner from './components/common/components/Spinner';
import './assests/custom/index.css';
import 'react-toastify/dist/ReactToastify.css';
import theme from './components/common/components/Theme';
import Sidebar from './components/SideBar/sideBar';
import { LogInProvider } from './isLoggedIn';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <LogInProvider>
        <ThemeProvider theme={theme}>
          <div style={{ display: 'flex' }}>
            <div className="layout-root">
              <Sidebar />
              <Suspense fallback={<Spinner />}>
                <Routes>
                  {RouteList.map((route) => (
                    <Route
                      key={route}
                      path={route.path}
                      exact
                      element={
                        route.auth ? (
                          <RequireAuth>
                            <route.element />
                          </RequireAuth>
                        ) : (
                          <CheckAuth>
                            <route.element />
                          </CheckAuth>
                        )
                      }
                    />
                  ))}
                </Routes>
              </Suspense>
            </div>
          </div>
        </ThemeProvider>
      </LogInProvider>
    </BrowserRouter>
  );
}

export default App;
