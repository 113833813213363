import React from 'react';
import { Box, Avatar } from '@mui/material';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { useNavigate } from 'react-router-dom';

export default function ActiveUserList({ data }) {
  const navigate = useNavigate();
  return (
    <Box className="profile-info mr-b">
      <Box className="info">
        <Avatar alt="Remy Sharp" src="https://picsum.photos/200/300" />
        <h6 className="profile">{`${data?.first_name || 'Maria'} ${data?.last_name || 'Francis'}`}</h6>
      </Box>
      <Box>
        <TrendingFlatIcon style={{ color: '#FA8010', cursor: 'pointer' }} onClick={() => navigate('/users/user-details', { state: data })} />
      </Box>
    </Box>
  );
}
