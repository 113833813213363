/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React from 'react';
import AuthHelper from '../../utils/AuthHelper';

export default function CheckAuth({ children }) {
  if (AuthHelper.validToken()) {
    window.location.replace('/dashboard');
    return '';
  }
  return children;
}
