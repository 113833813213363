/* eslint-disable comma-dangle */
import client from '../config/authClient';
import endpoints from '../endpoints';

const getSearchString = (s) => {
  if (s && s !== '') { return `&strSearchData=${s}`; }
  return '';
};
const Users = {
  getUsers: (offset, limit, strSearchData) => client.get(`${endpoints.user.user}?offset=${offset}&limit=${limit}${getSearchString(strSearchData)}`),
  create: (payload) => client.post(`${endpoints.user.user}`, payload),
  update: (userId, payload) => client.put(`${endpoints.user.user}/${userId}`, payload),
  delete: (id) => client.delete(`${endpoints.user.user}/${id}`),
  status: (id, body) => client.put(`${endpoints.user.user}/${id}`, body),
};

export default Users;
