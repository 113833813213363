import React, { useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DiningOutlinedIcon from '@mui/icons-material/Dining';
import CategoryOutlinedIcon from '@mui/icons-material/Category';
import {
  Drawer, Box, IconButton,
} from '@mui/material';
import Logo from '../../assests/images/logo-icon-white.png';
import { LogInContext } from '../../isLoggedIn';
import AuthHelper from '../../utils/AuthHelper';

const useStyles = makeStyles(() => ({
  drawerPaper: {
    border: 'none',
    padding: '24px',
    backgroundColor: '#f5f7fb',
    alignItems: 'center',
  },
  //   drawerPaperright: {
  //     width: drawerRightWidth,
  //     border: 'none',
  //     backgroundColor: '#eceef9',
  //     BorderColor: '#ecee9'
  //   },
  iconSize: { width: '2rem', height: '2rem' },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
function sideBar() {
  const { logIn } = useContext(LogInContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const subUrls = {
    fasting_plans: [
      '/fasting-plans/fasting-plan-details',
      '/fasting-plans/fasting-plan-details/plan-details',
    ],
    challenges: [
      '/challenges/challenge-details',
      '/challenges/trophies-details',
    ],
    users: ['/users/user-details'],
    faq: ['/faq/faq-details'],
  };
  const dataUrls = [
    {
      url: '/dashboard',
      name: 'Dashboard',
      icon: (
        <Tooltip title="Home">
          <HomeOutlinedIcon
            size="large"
            style={{
              color: currentPath === '/dashboard' ? '#ffff' : '#656565',
            }}
            className={classes.iconSize}
            onClick={() => navigate('/dashboard')}
          />
        </Tooltip>
      ),
    },
    {
      url: '/fasting-plans',
      subUrls: subUrls.fasting_plans,
      name: 'Fasting Plans',
      icon: (
        <Tooltip title="Fasting Plans">
          <InsertEmoticonOutlinedIcon
            className={classes.iconSize}
            style={{
              color:
                currentPath === '/fasting-plans'
                || subUrls.fasting_plans?.includes(currentPath)
                  ? '#ffff'
                  : '#656565',
            }}
            onClick={() => navigate('/fasting-plans')}
          />
        </Tooltip>
      ),
    },
    {
      url: '/challenges',
      subUrls: subUrls.challenges,
      name: 'Challenge Category',
      icon: (
        <Tooltip title="Challenge Category">
          <EmojiEventsOutlinedIcon
            className={classes.iconSize}
            style={{
              color:
                currentPath === '/challenges'
                || subUrls.challenges?.includes(currentPath)
                  ? '#ffff'
                  : '#656565',
            }}
            onClick={() => navigate('/challenges')}
          />
        </Tooltip>
      ),
    },
    {
      url: '/admin-settings',
      name: 'Admin settings',
      icon: (
        <Tooltip title="Settings">
          <SettingsOutlinedIcon
            className={classes.iconSize}
            style={{
              color: currentPath === '/admin-settings' ? '#ffff' : '#656565',
            }}
            onClick={() => navigate('/admin-settings')}
          />
        </Tooltip>
      ),
    },
    {
      url: '/users',
      subUrls: subUrls.users,
      name: 'Users',
      icon: (
        <Tooltip title="User">
          <PeopleAltOutlinedIcon
            className={classes.iconSize}
            onClick={() => navigate('/users')}
            style={{
              color:
                currentPath === '/users' || subUrls.users?.includes(currentPath)
                  ? '#ffff'
                  : '#656565',
            }}
          />
        </Tooltip>
      ),
    },
    {
      url: '/faq',
      subUrls: subUrls.faq,
      name: 'FAQ Category',
      icon: (
        <Tooltip title="FAQ Category">
          <HelpOutlineOutlinedIcon
            className={classes.iconSize}
            onClick={() => navigate('/faq')}
            style={{
              color:
                currentPath === '/faq' || subUrls.faq?.includes(currentPath)
                  ? '#ffff'
                  : '#656565',
            }}
          />
        </Tooltip>
      ),
    },
    {
      url: '/recipe',
      name: 'Recipe',
      icon: (
        <Tooltip title="Recipe">
          <DiningOutlinedIcon
            className={classes.iconSize}
            onClick={() => navigate('/recipe')}
            style={{ color: currentPath === '/recipe' ? '#ffff' : '#656565' }}
          />
        </Tooltip>
      ),
    },
    {
      url: '/recipe-category',
      name: 'Recipe Category',
      icon: (
        <Tooltip title="Recipe Category">
          <CategoryOutlinedIcon
            className={classes.iconSize}
            onClick={() => navigate('/recipe-category')}
            style={{
              color: currentPath === '/recipe-category' ? '#ffff' : '#656565',
            }}
          />
        </Tooltip>
      ),
    },
  ];
  const handleClick = () => {
    navigate('/dashboard');
  };
  return (
    <div>
      {logIn || AuthHelper.getAuthToken()
        ? (
          <Drawer
            className="layout-drawer"
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Box className="layout-logocontainer m-display" onClick={handleClick}>
              <img src={Logo} className="layout-logo" alt="logo" />
            </Box>
            <ui className="layout-list">
              <div className="layout-menuitem">
                {dataUrls?.map((item) => (
                  item?.url === currentPath
                  || item?.subUrls?.includes(currentPath) ? (
                    <IconButton key={item?.url} className="item-selected">
                      {item?.icon}
                    </IconButton>
                    ) : (
                      <IconButton key={item?.url}>{item?.icon}</IconButton>
                    )
                ))}
              </div>
            </ui>
          </Drawer>
        )
        : ''}
    </div>
  );
}

export default sideBar;
