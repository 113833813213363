/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Drawer, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import RightBarDrawerContent from './RightBarDrawerContent';

const drawerWidth = 355;
const drawerRightWidth = 340;
const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    border: 'none',
  },

  drawerPaperright: {
    width: drawerRightWidth,
    border: 'none',
    backgroundColor: '#ECEEF9',
    BorderColor: '#ECEEF9',
  },
  profile: { marginTop: '10px', marginLeft: '10px' },
  profileInfo: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  plans: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  ImgInfo: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
    borderRadius: 1,
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoContainer: {
    position: 'absolute',
    top: '0',
    right: '0',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px',
  },
  main: { padding: '1rem' },
  subHead: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
  },
  rightdrawerPaper: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

function RightBarDrawer({ open, setopen }) {
  const classes = useStyles();
  return (
    <>
      {open ? (
        <Drawer
          className="right-drawer"
          PaperProps={{
            className: classes.rightdrawerPaper,
            sx: {
              backgroundColor: '#f5f7fb',
            },
          }}
          variant={open ? 'permanent' : 'temporary'}
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaperright,
          }}
        >
          <RightBarDrawerContent setopen={setopen} open={open} />
        </Drawer>
      ) : (
        <Box className={classes.logoContainer}>
          <MenuIcon onClick={() => setopen(!open)} />
        </Box>
      )}
    </>
  );
}

export default RightBarDrawer;
