/* eslint-disable comma-dangle */
import client from '../config/authClient';
import endpoints from '../endpoints';

const getSearchString = (s) => {
  if (s && s !== '') { return `&strSearchData=${s}`; }
  return '';
};
const RECIPECATEGORY = {
  getRecipeCategory: (offset, limit, strSearchData) => client.get(`${endpoints.recipeCategory.recipeCategory}?offset=${offset}&limit=${limit}${getSearchString(strSearchData)}`),
  recipeCreate: (formData) => client.post(`${endpoints.recipeCategory.recipeCategory}`, formData),
  update: (payload) => client.put(`${endpoints.recipeCategory.recipeCategory}/${payload?.recipe_category_id}`, payload),
  delete: (id) => client.delete(`${endpoints.recipeCategory.recipeCategory}/${id}`),
  status: (id, body) => client.put(`${endpoints.plans.fastingPlans}/${id}`, body),
};

export default RECIPECATEGORY;
