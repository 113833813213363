/* eslint-disable comma-dangle */
import client from '../config/authClient';
import clientForm from '../config/UploadAPI';
import endpoints from '../endpoints';

const getSearchString = (s) => {
  if (s && s !== '') { return `&strSearchData=${s}`; }
  return '';
};
const Challenge = {
  getChallegeCategory: (offset, limit, strSearchData) => client.get(`${endpoints.challege.challenge}?offset=${offset}&limit=${limit}${getSearchString(strSearchData)}`),
  getTrophiesCategory: (offset, limit, strSearchData) => client.get(`${endpoints.challege.trophies}?offset=${offset}&limit=${limit}${getSearchString(strSearchData)}`),
  getChallege: (offset, limit, strSearchData) => client.get(`${endpoints.challege.challengelist}?offset=${offset}&limit=${limit}${getSearchString(strSearchData)}`),
  create: (payload) => client.post(`${endpoints.challege.challenge}`, payload),
  update: (payload) => client.put(`${endpoints.challege.challenge}/${payload?.category_id}`, payload),
  createTrophies: (payload) => clientForm.post(`${endpoints.challege.trophies}`, payload),
  updateTrophies: (payload) => clientForm.put(`${endpoints.challege.trophies}/${payload?.trophy_id}`, payload),
  deleteTrophies: (id) => client.delete(`${endpoints.challege.trophies}/${id}`),
  delete: (id) => client.delete(`${endpoints.challege.challenge}/${id}`),
  status: (id, body) => client.put(`${endpoints.challege.challenge}/${id}`, body),
  details: (id, offset, limit) => client.get(`${endpoints.challege.challenge}/${id}?offset=${offset}&limit=${limit}`),
  ChallengeDelete: (id) => client.delete(`${endpoints.challege.challegeDetails}/${id}`),
  statusDetails: (payload) => client.put(`${endpoints.challege.challegeDetails}/${payload?.challenge_id}`, payload),
  updateDetails: (payload) => client.put(`${endpoints.challege.challegeDetails}/${payload?.challenge_id}`, payload),
  deleteDetails: (id) => client.delete(`${endpoints.challege.challegeDetails}/${id}`),
  createDetails: (payload) => client.post(`${endpoints.challege.challegeDetails}`, payload),
  listImagePickers: () => client.get(`${endpoints.challege.imagePicker}`),

};

export default Challenge;
