/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RECIPECATEGORY from '../../utils/api/services/recipeCategory';
import Toast from '../common/components/Toast';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  className: 'center',
  variableWidth: true,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  slidesToShow: 1,
  centerPadding: '60px',
};
export default function TitlebarImageList() {
  const [data, setData] = useState('');

  const handleSuccess = (response) => {
    setData(response?.data);
  };

  const getRecipeCategory = async (pageNo, pagelimit) => {
    try {
      const pages = pageNo > 0 ? pageNo + 1 : 1;
      const offset = (pages - 1) * pagelimit;
      const response = await RECIPECATEGORY.getRecipeCategory(
        offset,
        pagelimit,
      );
      if (response?.status === 200) {
        handleSuccess(response);
      }
    } catch (err) {
      Toast('error', 'error');
    }
  };

  useEffect(() => {
    getRecipeCategory(0, 5);
  }, []);

  return (
    <div className="content">
      <Slider {...sliderSettings}>
        {data?.datas?.map((img, _index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={_index} className="itemboxes">
            <img alt={img?.title} src={img?.image_url} />
          </div>
        ))}
      </Slider>
    </div>
  );
}
