/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
import axios from 'axios';
import AuthHelper from '../../AuthHelper';
import { post } from './apiClient';

const API_URL = process.env.REACT_APP_BASE_URL;

const refreshToken = async () => {
  // Replace the following code with your actual token refresh logic
  const refreshedToken = await post('/auth/admin/refreshToken', {
    refreshToken: localStorage.getItem('refreshToken'),
  });
  AuthHelper.setAuthToken(refreshedToken.data.token);
  AuthHelper.setAuthRefresh(refreshedToken.data.refreshToken);
  return refreshedToken.data.token;
};
const authClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

authClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

authClient.interceptors.response.use(
  (response) => response,

  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // Call the refreshToken function to get a new access token
      const newAccessToken = await refreshToken();
      authClient.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
      return authClient(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default authClient;
