const dataUrl = [
  { url: '/dashboard', name: 'Dashboard' },
  { url: '/fasting-plans', name: 'Fasting Plans' },
  { url: '/challenges', name: 'Trophies' },
  { url: '/admin-settings', name: 'Admin Settings' },
  { url: '/faq', name: 'FAQ Category' },
  { url: '/fasting-plans/fasting-plan-details', name: 'Fasting Plan Details' },
  { url: '/challenges/challenge-details', name: 'Challenge  Details' },
  { url: '/challenges/trophies-details', name: 'Trophies  Details' },
  { url: '/users', name: 'Users' },
  { url: '/users/user-details', name: 'User Details' },
  { url: '/faq/faq-details', name: 'FAQ Details' },
  { url: '/fasting-plans/fasting-plan-details/plan-details', name: 'Fasting Plan Details' },
  { url: '/recipe', name: 'Recipe' },
  { url: '/recipe-category', name: 'Recipe Category' },

];

export default dataUrl;
