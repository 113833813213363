/* eslint-disable comma-dangle */
import { lazy } from 'react';

const routes = [
  {
    path: '/dashboard',
    element: lazy(() => import('../pages/dashboard')),
    auth: true
  },
  {
    path: '/fasting-plans',
    element: lazy(() => import('../pages/fastingPlan')),
    auth: true
  },
  {
    path: '/fasting-plans/fasting-plan-details',
    element: lazy(() => import('../pages/fastingPlan/view')),
    auth: true
  }, {
    path: '/challenges',
    element: lazy(() => import('../pages/challenges')),
    auth: true
  }, {
    path: '/challenges/challenge-details',
    element: lazy(() => import('../pages/ChallegDetails')),
    auth: true
  }, {
    path: '/challenges/trophies-details',
    element: lazy(() => import('../pages/TrophiesDetails')),
    auth: true
  }, {
    path: '/admin-settings',
    element: lazy(() => import('../pages/settings')),
    auth: true
  }, {
    path: '/users',
    element: lazy(() => import('../pages/users')),
    auth: true
  }, {
    path: '/users/user-details',
    element: lazy(() => import('../pages/UserDetails')),
    auth: true
  }, {
    path: '/faq',
    element: lazy(() => import('../pages/faq')),
    auth: true
  }, {
    path: '/faq/faq-details',
    element: lazy(() => import('../pages/faqDetails')),
    auth: true
  },
  {
    path: '/fasting-plans/fasting-plan-details/plan-details',
    element: lazy(() => import('../pages/fastingPlanDetails')),
    auth: true
  }, {
    path: '/recipe',
    element: lazy(() => import('../pages/recipe')),
    auth: true
  }, {
    path: '/recipe-category',
    element: lazy(() => import('../pages/recipeCategory')),
    auth: true
  }
];
export default routes;
