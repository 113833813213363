import { toast as toasts } from 'react-toastify';

// type - success/error
const Toast = (message, type, loadFun) => {
  toasts(message, {
    hideProgressBar: true,
    theme: 'colored',
    autoClose: 2000,
    type,
    onClose: loadFun,
  });
};
export default Toast;
