import React, { memo, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import AuthHelper from '../../utils/AuthHelper';
import RightBarDrawer from '../Layout/RightDrawer';
import AppHeader from '../Layout/AppHeader';

function RequireAuth({ children }) {
  if (!AuthHelper.getAuthToken()) {
    return <Navigate to="/login" />;
  }
  const [rightOpen, setRightopen] = useState(false);

  useEffect(() => {
    setRightopen(window.location.pathname === '/dashboard');
  }, [children]);
  return (
    <main className="layout-content-close">
      <div>
        <AppHeader />
        <div>
          {/* <AppLayout> */}
          {children}
          {/* </AppLayout> */}
        </div>
        <RightBarDrawer open={rightOpen} setopen={setRightopen} />
      </div>
    </main>
  );
}

export default memo(RequireAuth);
