/* eslint-disable comma-dangle */
const endpoints = {
  auth: {
    login: '/auth/admin/login',
    refreshToken: '/auth/refreshToken',
    forgotPassword: '/auth/admin/forgotPassword',
    resetPassword: '/auth/admin/resetPassword'
  },
  plans: {
    fastingPlans: '/api/admin/fasting-plan-groups',
    fastingPlansDetails: '/api/admin/fasting-plans',
    challege: '/api/admin/fasting-plans/challenge',
    trophy: '/api/admin/fasting-plans/trophy',
    fastingPlanPeriod: '/api/admin/fasting-plans/period',
    planchallege: '/api/admin/fasting-plans/challenge​',
  },
  challege: {
    challenge: '/api/admin/challenges_categories',
    trophies: '/api/admin/trophy',
    challegeDetails: '/api/admin/challenges/challenge',
    challengelist: '/api/admin/challenges',
    imagePicker: '/api/admin/image'
  },
  password: {
    changePassword: '/api/admin/users',
    adminchangePassword: 'auth/admin/details'
  },
  user: {
    user: '/api/admin/users'
  },
  faq: {
    faq: '/api/admin/faqs'
  },
  faqDetails: {
    faq: '/api/admin/faqs',
    faqDetails: 'api/admin/faqs/faq',
  },
  Period: {
    Period: '/api/admin/fasting-plans/period'
  },
  recipe: {
    recipe: '/api/admin/recipe',
    recipeOfWeek: '/api/admin/recipe/recipeOfWeek'
  },
  recipeCategory: {
    recipeCategory: '/api/admin/recipe/categories',
    upload: '/api/admin/recipe/categories'
  },
  tag: {
    tag: '/api/admin/recipe/tag',
    fastingTag: '/api/admin/fasting-plan-tags',
    FastingPlantag: '/api/user/fastingPlanTags'
  }

};

export default endpoints;
